<template>
  <div class="req-temp-container mt-6">
    <vs-card>
      <div slot="header">
        <div class="flex items-center">
          <h3 class="font-normal text-lg md:text-xl lg:text-2xl">Merchants</h3>
        </div>
      </div>
      <vs-table :data="merchants" class="stripes merch-list">
        <template slot="thead">
          <vs-th> Merchant ID </vs-th>
          <vs-th> Merchant name</vs-th>
          <vs-th> Status</vs-th>
          <vs-th></vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.merchantId">
              {{ tr.merchant.merchantId }}
            </vs-td>
            <vs-td :data="tr.companyName">
              {{ tr.merchant.companyName }}
            </vs-td>
            <vs-td :data="tr.status">
              <span :class="changeStatusColor(tr.status)">
                {{ tr.status == "Connected" ? "Linked" : tr.status }}</span
              >
            </vs-td>

            <vs-td :data="tr" class="text-right">
              <a
                class="pl-5 pr-5 underline"
                @click="gotoDetail(tr.merchant._id)"
                v-if="tr.status === 'Connected'"
                >View</a
              >
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div>
        <vs-row class="px-2 mt-8 mb-6 flex justify-center sm:justify-between items-center">
          <div class="flex mb-6 sm:mb-0 items-center gap-4">
            <span class="text-base">Records per page</span>
            <vs-select v-model="limit" class="records-per-page">
              <vs-select-item
                v-for="(item, index) in limitValue"
                :key="index"
                class=""
                :text="item.text"
                :value="item.value"
              />
            </vs-select>
          </div>

          <v-pagination v-model="pageNumber" :page-count="totalPage"></v-pagination>
        </vs-row>
      </div>
    </vs-card>
  </div>
</template>
<script>
import { mapActions } from "vuex";

import vPagination from "@/views/components/pagination";

export default {
  components: { vPagination },
  data() {
    return {
      merchants: [],
      pageNumber: 1,
      limit: 50,
      limitValue: [
        { text: "1", value: 1 },
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      totalData: 0,
    };
  },
  watch: {
    pageNumber(val) {
      this.getLinkRequestList();
    },
    limit(val) {
      this.getLinkRequestList();
    },
  },
  methods: {
    ...mapActions("merchant", ["fetchMerchantList"]),

    async getLinkRequestList() {
      const param = `${this.introducerId}?page=${this.pageNumber}&limit=${this.limit}`;
      this.fetchMerchantList(param)
        .then((response) => {
          this.merchants = response.data.data.docs;
          this.totalData = response.data.data.pagination.total;
        })
        .catch((err) => {
          
        });
    },
    changeStatusColor(status) {
      let bgClass = "";
      if (status == "Rejected") {
        bgClass = "badge danger";
      } else if (status == "Connected") {
        bgClass = "badge success";
      } else if (status == "Pending") {
        bgClass = "badge pending";
      } else {
        bgClass = "badge primary";
      }
      return bgClass;
    },
    gotoDetail(id) {
      this.$router.push({
        name: "merchant-detail",
        params: {
          id: id,
        },
      });
    },
  },
  mounted() {
    this.getLinkRequestList();
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem("userInfo"));
    },
    introducerId() {
      return this.user.userType === "superAdmin"
        ? this.user._id
        : this.user.introducer;
    },
    totalPage: function () {
      return Math.ceil(this.totalData / this.limit);
    },
  },
};
</script>
